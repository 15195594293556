@import "_config";
@import "_slider";

body {
  font-family: "proxima-nova", sans-serif;
  // font-family: sans-serif;
  font-weight: 300;
  margin: 0;
  line-height: 1.4;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

html {
  font-size: 16px;
}

body * {
  box-sizing: inherit;
}

// Extensions

.is-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px 20px;
}

.is-inverted {
  background: $dark-grey;
  color: $white;
}

.is-key-fact {
  font-weight: 700;
  text-align: center;
  font-size: 0.9rem;
  margin: 20px 0;
}

.is-key-fact img {
  max-width: 50px;
  display: block;
  margin: 10px auto;
}

.is-narrow-container {
  padding: 50px 20px;
  max-width: 840px;
  margin: auto;
}

.is-narrow-container .is-row > *:first-child {
  flex-basis: 60%;
  padding-right: $gap;
}

.is-narrow-container .is-row > *:last-child {
  flex-basis: 40%;
  padding-left: $gap;
}

.is-container {
  max-width: 80%;
  margin: auto;
  padding: 50px 0;
}

.is-row {
  display: flex;
  align-items: center;
}

.is-row.is-aligned-top {
  align-items: flex-start;
}

.is-row > * {
  width: 50%;
}

.is-row > *:first-child {
  padding-right: $gap;
}

.is-row > *:last-child {
  padding-left: $gap;
}

.button {
  font-family: inherit;
  background: $golden-brown;
  color: $white;
  padding: 15px 25px;
  border: 0;
  border-radius: 25px;
  font-size: 0.8rem;
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}

// Elements

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h2 span {
  color: $golden-brown;
}

p {
  margin-bottom: 20px;
}

img {
  max-width: 100%;
}

#kontaktformular.sent {
  opacity: 0.8;
  transition: 0.2s ease-out;
  pointer-events: none;

  &:after {
    content: "Nachricht gesendet";
    display: block;
    margin-top: 20px;
  }
}

label {
  display: inline-block;
  width: calc(50% - 3px);
  padding-right: 10px;
  margin: 5px 0;
  font-size: 0.8rem;
}

label.full-width {
  width: calc(100% - 1px);
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
  padding: 8px;
  margin-top: 5px;
  display: block;
  width: 100%;
  font-family: inherit;
  border-radius: 0;
  border: none;

  &::placeholder {
    color: #a4a4a4;
    font-size: 0.7rem;
  }
}

// Comps

.contact-sidebar {
  font-size: 0.8rem;
}

.contact-sidebar img {
  margin: 20px 0;
  max-width: 200px;
}

.contact-sidebar h3,
.contact-sidebar h4,
.contact-sidebar .call-heading,
.contact-sidebar .telephone-number {
  font-size: 1.2rem;
  margin: 0;
}

.contact-sidebar .call-heading {
  color: $golden-brown;
}

.contact-sidebar .telephone-number {
  display: flex;
  align-items: center;

  a {
    color: white;
    text-decoration: none;
  }

  img {
    max-width: 20px;
    margin-right: 10px;
  }
}

.logo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  padding: 60px 20px;
  width: 150px;
  border-bottom-right-radius: 45px;
  z-index: 1;
}

.content {
  position: absolute;
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
  max-width: 900px;
  z-index: 1;
}

.element-bottom-left {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300px;
  z-index: 1;
}

.element-top-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 300px;
  z-index: 1;
}

.stripes {
  position: absolute;
  left: 10%;
  top: 0;
  right: 10%;
  bottom: 0;
  border-left: 1px solid $light-grey;
  z-index: -1;
  display: flex;
}

.stripes div {
  flex-basis: calc(100% / 6);
  border-right: 1px solid $light-grey;
}

// Sections

#hero {
  background: url(../assets/the-oval-balkonansicht_.jpg);
  background-size: cover;
  background-position: bottom;
  min-height: 600px;
  color: $white;
  position: relative;
}

#hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $dark-grey;
  opacity: 0.5;
  z-index: 0;
}

#hero h1 {
  text-transform: uppercase;
  letter-spacing: 9px;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0;
}

#hero h2 {
  font-weight: 100;
  font-size: 1.8rem;
  opacity: 0.7;
  margin: 0;
}

#contact h2 {
  margin: 0;
}

#contact h2 + p {
  margin-bottom: 50px;
}

footer {
  background: black;
}

footer ul {
  color: white;
  margin: 0 auto;
  max-width: 840px;
  padding: 0;
  padding: 10px 20px;
}

footer ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 0.8rem;

  &:not(:last-child):after {
    content: "|";
    transform: translateX(10px);
    display: inline-block;
  }
}

footer ul li a {
  color: white;
  text-decoration: none;
}

@import "_responsive";
