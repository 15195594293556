@media (max-width:800px) {
  .is-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .is-row {
    flex-direction: column;
  }

  .is-row > *,
  .is-narrow-container .is-row > * {
    width: 100%;
  }

  .is-row > *:first-child,
  .is-row > *:last-child,
  .is-narrow-container .is-row > *:first-child,
  .is-narrow-container .is-row > *:last-child {
    padding-left: 0;
    padding-right: 0;
  }

  .contact-sidebar {
    padding-top: 30px;
    text-align: center;
  }

  .telephone-number {
    justify-content: center;
  }
}

@media (max-width:600px) {
  .is-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 10px;
  }

  .is-key-fact {
    font-size: .7rem;
  }

  .is-key-fact img {
    max-width: 40px;
  }

  #hero .element-bottom-left, 
  #hero .element-top-right {
    height: 150px;
  }

  #hero .content {
    top: 240px;
    width: 370px;
    transform: translateX(-47%);
    max-width: 90%;
  }

  #hero h1,
  #hero h2 {
    font-size: 1.6rem;
  }
}