@import "../../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";

.glide__bullets {
  display: flex;
}

.glide__bullet {
  padding: 0;
  border: none;
  cursor: pointer;
  
  &:not(:last-child) {
    margin-right: 5px;
  }
}
