.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}
.glide__bullets {
  display: flex;
}
.glide__bullet {
  padding: 0;
  border: none;
  cursor: pointer;
}
.glide__bullet:not(:last-child) {
  margin-right: 5px;
}
body {
  font-family: "proxima-nova", sans-serif;
  font-weight: 300;
  margin: 0;
  line-height: 1.4;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}
html {
  font-size: 16px;
}
body * {
  box-sizing: inherit;
}
.is-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px 20px;
}
.is-inverted {
  background: #2c3031;
  color: white;
}
.is-key-fact {
  font-weight: 700;
  text-align: center;
  font-size: 0.9rem;
  margin: 20px 0;
}
.is-key-fact img {
  max-width: 50px;
  display: block;
  margin: 10px auto;
}
.is-narrow-container {
  padding: 50px 20px;
  max-width: 840px;
  margin: auto;
}
.is-narrow-container .is-row > *:first-child {
  flex-basis: 60%;
  padding-right: 30px;
}
.is-narrow-container .is-row > *:last-child {
  flex-basis: 40%;
  padding-left: 30px;
}
.is-container {
  max-width: 80%;
  margin: auto;
  padding: 50px 0;
}
.is-row {
  display: flex;
  align-items: center;
}
.is-row.is-aligned-top {
  align-items: flex-start;
}
.is-row > * {
  width: 50%;
}
.is-row > *:first-child {
  padding-right: 30px;
}
.is-row > *:last-child {
  padding-left: 30px;
}
.button {
  font-family: inherit;
  background: #977951;
  color: white;
  padding: 15px 25px;
  border: 0;
  border-radius: 25px;
  font-size: 0.8rem;
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}
h2 {
  font-size: 2rem;
  font-weight: 500;
}
h2 span {
  color: #977951;
}
p {
  margin-bottom: 20px;
}
img {
  max-width: 100%;
}
#kontaktformular.sent {
  opacity: 0.8;
  transition: 0.2s ease-out;
  pointer-events: none;
}
#kontaktformular.sent:after {
  content: "Nachricht gesendet";
  display: block;
  margin-top: 20px;
}
label {
  display: inline-block;
  width: calc(50% - 3px);
  padding-right: 10px;
  margin: 5px 0;
  font-size: 0.8rem;
}
label.full-width {
  width: calc(100% - 1px);
}
input[type=text],
input[type=tel],
input[type=email],
textarea {
  padding: 8px;
  margin-top: 5px;
  display: block;
  width: 100%;
  font-family: inherit;
  border-radius: 0;
  border: none;
}
input[type=text]::placeholder,
input[type=tel]::placeholder,
input[type=email]::placeholder,
textarea::placeholder {
  color: #a4a4a4;
  font-size: 0.7rem;
}
.contact-sidebar {
  font-size: 0.8rem;
}
.contact-sidebar img {
  margin: 20px 0;
  max-width: 200px;
}
.contact-sidebar h3,
.contact-sidebar h4,
.contact-sidebar .call-heading,
.contact-sidebar .telephone-number {
  font-size: 1.2rem;
  margin: 0;
}
.contact-sidebar .call-heading {
  color: #977951;
}
.contact-sidebar .telephone-number {
  display: flex;
  align-items: center;
}
.contact-sidebar .telephone-number a {
  color: white;
  text-decoration: none;
}
.contact-sidebar .telephone-number img {
  max-width: 20px;
  margin-right: 10px;
}
.logo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  padding: 60px 20px;
  width: 150px;
  border-bottom-right-radius: 45px;
  z-index: 1;
}
.content {
  position: absolute;
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
  max-width: 900px;
  z-index: 1;
}
.element-bottom-left {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300px;
  z-index: 1;
}
.element-top-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 300px;
  z-index: 1;
}
.stripes {
  position: absolute;
  left: 10%;
  top: 0;
  right: 10%;
  bottom: 0;
  border-left: 1px solid #eeeeee;
  z-index: -1;
  display: flex;
}
.stripes div {
  flex-basis: calc(100% / 6);
  border-right: 1px solid #eeeeee;
}
#hero {
  background: url(the-oval-balkonansicht_.164ec3aa.jpg);
  background-size: cover;
  background-position: bottom;
  min-height: 600px;
  color: white;
  position: relative;
}
#hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2c3031;
  opacity: 0.5;
  z-index: 0;
}
#hero h1 {
  text-transform: uppercase;
  letter-spacing: 9px;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0;
}
#hero h2 {
  font-weight: 100;
  font-size: 1.8rem;
  opacity: 0.7;
  margin: 0;
}
#contact h2 {
  margin: 0;
}
#contact h2 + p {
  margin-bottom: 50px;
}
footer {
  background: black;
}
footer ul {
  color: white;
  margin: 0 auto;
  max-width: 840px;
  padding: 0;
  padding: 10px 20px;
}
footer ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 0.8rem;
}
footer ul li:not(:last-child):after {
  content: "|";
  transform: translateX(10px);
  display: inline-block;
}
footer ul li a {
  color: white;
  text-decoration: none;
}
@media (max-width: 800px) {
  .is-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .is-row {
    flex-direction: column;
  }

  .is-row > *,
.is-narrow-container .is-row > * {
    width: 100%;
  }

  .is-row > *:first-child,
.is-row > *:last-child,
.is-narrow-container .is-row > *:first-child,
.is-narrow-container .is-row > *:last-child {
    padding-left: 0;
    padding-right: 0;
  }

  .contact-sidebar {
    padding-top: 30px;
    text-align: center;
  }

  .telephone-number {
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .is-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 10px;
  }

  .is-key-fact {
    font-size: 0.7rem;
  }

  .is-key-fact img {
    max-width: 40px;
  }

  #hero .element-bottom-left,
#hero .element-top-right {
    height: 150px;
  }

  #hero .content {
    top: 240px;
    width: 370px;
    transform: translateX(-47%);
    max-width: 90%;
  }

  #hero h1,
#hero h2 {
    font-size: 1.6rem;
  }
}
/*# sourceMappingURL=index.64c95825.css.map */
